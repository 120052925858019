import ChartsEmbedSDK, {
  getRealmUserToken,
} from "@mongodb-js/charts-embed-dom";
import * as Realm from "realm-web";

const client = new Realm.App({
  id: "application-0-caldvrf", 
});

function getUser() {
  return document.getElementById("username").value;
}

function getPass() {
  return document.getElementById("password").value;
}

function logOut() {
  document.body.classList.toggle("logged-in", false);
  hideLogoutButton();
  clearDashContainer();
  clearLoginFields();
}

function showLogoutButton() {
  const logoutButton = document.getElementById('logoutButton');
  logoutButton.style.display = 'block'; // Shows the button
}

// Function to hide the logout button
function hideLogoutButton() {
  const logoutButton = document.getElementById('logoutButton');
  logoutButton.style.display = 'none'; // Hides the button
}

function clearDashContainer() {
  const chartContainer = document.getElementById('dash');
  chartContainer.innerHTML = ''; // Clears the contents of the chart container
}

function clearLoginFields() {
  document.getElementById('username').value = ''; // Clear username field
  document.getElementById('password').value = ''; // Clear password field
}

document
  .getElementById("login-page")
  .addEventListener("input", () =>
    document.body.classList.toggle("error", false)
  );

document
  .getElementById("loginButton")
  .addEventListener("click", () => tryLogin());

document
  .getElementById("logoutButton")
  .addEventListener("click", () => logOut());

async function tryLogin() {
  const credentials = Realm.Credentials.emailPassword(getUser(), getPass());

  try {
    await client.logIn(credentials);
    const sdk = new ChartsEmbedSDK({
      baseUrl: "https://charts.mongodb.com/charts-flexlab-pm-kgfoudl", 
      getUserToken: () => getRealmUserToken(client),
    });

    const chart = sdk.createChart({
      chartId: "8f54d0df-57da-4b83-900d-52b1f5c23ddc", 
    });

    const dash = sdk.createDashboard({
      dashboardId: "66b06e93-61ff-45bf-84eb-da1046aa4883", 
      height:1500,
      showAttribution: false,
      autoRefresh: true,
      maxDataAge: 120,
    });


    chart.render(document.getElementById("chart"));
    dash.render(document.getElementById("dash"));
    document.body.classList.toggle("logged-in", true);
    showLogoutButton();
  } catch (err) {
    console.error(
      "Authentication failed."
    );
    document.body.classList.toggle("error", true);
  }
}
